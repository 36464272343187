<template>
  <div>
    <AboutUsHero title="Campus" />
    <div class="mx-4 mt-3">
      <sector 
        v-for="campus of campuses" 
        :key="campus.name" 
        :hide-schools="campus.schools ? false : true" 
        :sector-name="campus.name" 
        :schools="campus.schools || []"
        title="List of Coordination Schools"
        v-bind="campus" 
      />
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BImg,
  BCard,
  BCardText,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BButton
} from "bootstrap-vue";

import AboutUsHero from "@/@core/components/public/AboutUsHero.vue";

import UEW from "@/assets/images/sector-images/UEW.jpg"
import LEGON from "@/assets/images/sector-images/LEGON.jpg"
import UCC from "@/assets/images/sector-images/Pastor f .jpg"
import PENTECOST from "@/assets/images/sector-images/PU.jpg"
import KNUST from "@/assets/images/sector-images/KNUST.jpg"
import Sector from "@/@core/components/sector/Sector.vue";


export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    AboutUsHero,
    BButton,
    Sector
  },
  data() {
    return {
      campuses: [
        {
          name: "PENSA UEW",
          pastor: "PASTOR RICHARD",
          wife: "MRS. KEZIAH ATOBRAH",
          text: `
          PENSA-UEW is one of the newly-introduced campus ministries under the PENSA system. It has a
          total membership of about 2,998 and different branches on campus; French, North and South
          branches. Pastor Richard is the resident campus pastor of PENSA-UEW, with support from his wife,
          Mrs. Keziah Yvonne Atobrah.
          `,
          stats: [
            "Population of 2998 Student membership",
            "3 Branch Services; French, North and South Branches"
          ],
          image: UEW
        },
        {
          name: "PENSA LEGON (UG)",
          pastor: "Pastor Kwadwo Obeng",
          wife: "Mrs. Eunice Obeng",
          text: `
          PENSA-Legon is one of the dominant Pentecostal denomination on the University of Ghana
          campus. It has a membership of over 2,000 students under the spiritual leadership of Pastor
          Kwadwo Obeng and his wife, Mrs. Eunice Obeng. There are 3 coordinating pre-tertiary
          schools; St. Johns Grammar, PRESEC-Legon and Kwabenya SHS. Additionally, GIMPA and
          Ashesi University are under the coordination of PENSA-Legon.
          PENSA-Legon is one of the dominant Pentecostal denomination on the University of Ghana campus. 
          It has a membership of over 2,000 students under the spiritual leadership of Pastor Kwadwo Obeng and his wife, Mrs. Eunice Obeng. 
          There are 3 coordinating pre-tertiary schools; St. Johns Grammar, PRESEC-Legon and Kwabenya SHS. Additionally, GIMPA and Ashesi University are under the coordination of PENSA-Legon.
          `,
          stats: [
            "Population of 2,000 Student membership",
            "3 Services in 3 Institutions",
            "3 Pre-tertiary Coordinating School"
          ],
          schools: [
            'St. Johns Grammar',
            'PRESEC-Legon',
            'Kwabenya SHS'
          ],
          image: LEGON
        },
        {
          name: "PENSA UCC",
          pastor: "Pastor Frank",
          wife: "Mrs. Eunice Akonnor",
          text: `
          PENSA-UCC is dedicated to the holistic development of students and is among the few
          campus ministries. The resident minister is Pastor Frank and Mrs. Eunice Akonnor. With two
          branches; French and English, operating on the same campus, the total membership is 3,262.
          English branch runs two services every Sunday at the University of Cape Coast Main
          Auditorium. The French branch fellowships at SRT 1. PENSA-UCC also runs a radio
          ministry, Children’s Ministry, HUM, MPWD and coordinates 11 Senior High Schools in the
          Central region of Ghana. In line with the holistic mandate, PENSA-UCC has Student
          Workers’ Guild and Professional Chambers.
          `,
          stats: [
            "Population of 3,262 Student membership",
            "2 Branch Services, English and French",
            "11 Pre-tertiary Coordinating School"
          ],
          image: UCC
        },
        {
          name: "PENTECOST UNIVERSITY",
          pastor: "Rev. Nelson",
          wife: "Mrs. Celestine Adu",
          text: `
          PENSA-PU is one of the Campuses operating under the chaplaincy of Rev. Nelson and
          Mrs. Celestine Adu.
          `,
          stats: [
            "Population of 2,510 Student membership",
            "2 Branch Services, English and French",
            "6 Pre-tertiary Coordinating School"
          ],
          schools: [
            'Pentecost Vocational and Training Institute',
            'Christian Methodist SHS',
            'Odorgonno SHS',
            'St. Anson SHS',
            'Ngleshie Amanfrom SHS',
            'GH Media School, Accra.'
          ],
          image: PENTECOST
        },
        {
          name: "PENSA KNUST",
          pastor: "Pastor Jonathan Ebo",
          wife: "Mrs. Joana Dwomoh Quaye",
          text: `
          PENSA KNUST is a vibrant campus ministry in the Ashanti region of Ghana. The institution is
          blessed to mentored under the leadership and tutelage of our Resident Campus Pastor and Wife,
          Pastor Jonathan Ebo and Mrs. Joana Dwomoh Quaye. The group is blessed to have a membership of
          over 3,000 with a large percentage of CoP students and the rest being associates.

          We run 3 power-packed services every Sunday including that of our fast-growing French and
          International Students Assembly.

          Our mandate as a church is to be among the most relevant churches on campus, whose members are
          grounded in God's word for maximum impact on campus, Ghana and the world, at large.
          `,
          stats: [
            "Population of 3000 Student membership",
            "2 Branch Services; International Student service and French"
          ],
          image: KNUST
        }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
.accordion-title {
  background-color: #021977;
  color: #ffd801;
  width: 200px;
  display: flex;
  font-size: 20px;
  padding: 5px;
  border-radius: 5px;
  justify-content: space-center;
  align-items: center;
}

.rod-accordion {
  margin-left: 130px;
  // margin-right: 500px;
  width: 50vw;
  height: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: gray;
}

.stat {
  background-color: #899ff5;
  border-radius: 5px;
  color: black;
  padding: 5px;
  font-size: 1.25rem;
}
.side-note {
  position: relative;
  bottom: 12%;
  left: 31.5%;
  background-color: #021977;
  color: white;
  width: 240px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  // border-radius: 15px;
  border-left: 10px solid #ffd801;
  font-weight: bolder;
}

.side-note-title {
  color: white;
  font-size: 15px;
}

.side-note-2 {
  position: relative;
  bottom: 52%;
  left: 135%;
  font-size: medium;
}

h1 {
  font-size: 3rem;
  font-weight: normal;
}

.text-yellow {
  color: yellow;
}
.text-lg {
  font-size: 15px;
}
.img-container {
  height: 400px;
  widows: 300px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

ol {
  font-size: 15px;
  li {
    margin-bottom: 10px;
  }
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
}
</style>
